<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" >
    <MDBCardBody>
      <MDBContainer fluid class="mt-3">
        <DataTable
          :header="headers"
          :tabledata="desserts"
          v-model:total="total"
          v-model:options="options"
          v-model:loading="loading"
        >
          <template v-slot:created_at="{ item }">
            {{ Common.TimeFormat(item.created_at) }}
          </template>
        </DataTable>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBContainer, MDBCard, MDBCardBody } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';
export default {
  name: "OperateRecord",
  components: {
		DataTable,
		MDBContainer,
		Breadcrumbs,
    MDBCard,
    MDBCardBody
  },
  setup() {
    const i18n = useI18n();
    const loading = ref(true);
    const total = ref(0);
    const Common = CommonService;
    const desserts = ref([]);
    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: ["desc"],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });
    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",
        width: 50
      },
      { text: i18n.t("COMMON.ACCOUNT"), value: "user.email" },
      { text: i18n.t("COMMON.NAME"), value: "user.name" },
      { text: i18n.t("COMMON.OPERATE"), value: "event", width: 80 },
      { text: i18n.t("COMMON.OPERATEITEM"), value: "auditable_type", width: 150 },
      { text: "操作時間", value: "created_at", width: 150 },
      { text: i18n.t("COMMON.SOURCEIP"), value: "ip_address" },
      { text: i18n.t("COMMON.DEVICE"), value: "user_agent" }
    ]);

    const GetOperateRecord = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/OperateRecord",
          {
            params: CommonService.SortHandleObject(options)
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    }

		return {
      loading,
      options,
      headers,
      total,
      desserts,
      Common,
      GetOperateRecord
		};
  },
  mounted() {
    this.GetOperateRecord();
  },
  watch: {
    options: {
      handler() {
        this.GetOperateRecord();
      },
      deep: true
    }
  }
};
</script>